<template lang="pug">
  v-container
    v-row
      v-col
        .details.mb-10
          v-layout(
            align-center
          )
            h1.text-h4 {{ user.fullName }}
            v-col
              v-chip(
                :color="user.status === 'ACTIVE' ? 'primary' : 'grey lighten-1'"
                small
                v-if="user.isAdmin"
              ) {{ user.status }}
              v-chip(
                :color="primary"
                small
              ) {{ user.permissionsDisplay }}
              .actions.ml-auto(
                v-if="currentUser.isAdmin || currentUser.isRegionAdmin"
              )
                v-tooltip(top)
                  template(#activator="{ on, attrs}")
                    v-btn.px-0.mr-2.my-1(
                      v-on="on"
                      min-width="40px"
                      @click="deleteUser"
                      color="error"
                    )
                      v-icon mdi-delete
                  span Delete User
                v-btn.ml-auto(
                  :to="{ name: 'edit-user', params: { userId: user.id }}"
                  color="primary"
                )
                  v-icon(
                    left
                  ) mdi-pencil
                  | Edit User
          p {{ user.username }}
            span.email(
              v-if="user.email"
            )
              | &nbsp;&middot;&nbsp;
              a(
                :href="`mailto:${user.email}`"
              ) {{ user.email }}
            span.phone(
              v-if="user.phone"
            )
              | &nbsp;&middot;&nbsp;
              a(:href="`tel:${user.phone}`") {{ user.phone }}
          p(v-if="user.createdBy") Added By:&nbsp;
            a(
              v-if="user.createdBy"
              :href="{ name: 'user', params: { userId: user.createdBy.id }}"
            ) {{ user.createdBy.fullName }}

</template>

<script>
import { useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

export default {
  name: 'User',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { User } = context.root.$FeathersVuex.api

    const params = computed(() => {
      return { query: { $eager: 'createdBy' } }
    })

    const { item: user, hasLoaded } = useGet({
      model: User,
      id: props.userId,
      params
    })

    return {
      user,
      hasLoaded
    }
  },
  methods: {
    async deleteUser () {
      try {
        const message = 'Are you sure you want to delete this user?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        if (isYes) {
          const user = this.user

          await this.user.remove()

          this.$snackSuccess('User Deleted')

          if (user.shop_id !== null) {
            this.$router.push({ name: 'shop-users', params: { shopId: user.shop_id } })
          } else if (user.region_id !== null) {
            this.$router.push({ name: 'region', params: { regionId: user.region_id } })
          }
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/user']
    }
  }
}
</script>
